import { FormField, IFormFieldProps, TFormFieldBaseComponent } from "@ui";
import { FieldValues, Path, useController, UseControllerProps } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface IControllerFormFieldProps<TComponent, TFieldValues extends FieldValues>
    extends Omit<IFormFieldProps<TComponent>, "onChange" | "name">,
        UseControllerProps<TFieldValues, Path<TFieldValues>> {
    onChange?: (eventOrValue: any) => void;
}

export const ControlledFormField = <TComponent extends TFormFieldBaseComponent, TFieldValues extends FieldValues>({
    control,
    name,
    rules,
    defaultValue,
    onChange,
    ...formFieldProps
}: IControllerFormFieldProps<TComponent, TFieldValues> & Parameters<TComponent>[0]) => {
    const { t } = useTranslation("common");
    const {
        field: { onChange: controlledOnChange, onBlur, value = "", ref },
        fieldState: { error },
    } = useController<TFieldValues>({
        name,
        control,
        rules,
        defaultValue,
    });

    const handleChange = (event: any) => {
        controlledOnChange(event);

        if (onChange) {
            onChange(event);
        }
    };

    console.log("hiero1.3", "name", name, "error", error);

    const resolvedErrorMessage = () => {
        if (typeof error === "string") {
            return t(error);
        } else if (error && typeof error === "object") {
            if (error.message) {
                return error.message;
                // return t(error.message);
            } else if (error.type) {
                // return error.type;
                return t(`validate.${error.type}`) || error.type;
            }
        }
    };

    const { error: _, ...restFormFieldProps } = formFieldProps;

    return (
        <FormField
            error={resolvedErrorMessage()}
            {...restFormFieldProps}
            name={name}
            onChange={handleChange}
            onBlur={onBlur}
            value={value}
            ref={ref}
        />
    );
};
